<template>
  <div class="my-10">
    <div class="w-full">
      <div class="flex items-center">
        <h1 class="w-2/5">Name</h1>
        <h1 class="w-1/5">Age</h1>
        <h1 class="w-1/5">Cause of Death</h1>
        <h1 class="w-1/5">Tag Number</h1>
        <h1 class="w-1/5">Gender</h1>
        <h1 class="w-1/5" v-if="actions"></h1>
      </div>
      <template v-for="corpse in corpses">
        <div
          class="flex items-center bg-white py-3 px-5 rounded-md my-4 hover:shadow-md cursor-pointer"
          :key="corpse.id"
          v-if="corpse.status !== '0'"
        >
          <div class="w-2/5 flex items-center gap-3">
            <img
              :src="corpse.image"
              class="w-auto h-10 rounded-full"
              :placeholder="corpse.name_of_deceased"
            />
            {{ corpse.name_of_deceased }}
          </div>
          <div class="w-1/5">
            {{ corpse.age_of_deceased }}
          </div>
          <div class="w-1/5">
            {{ corpse.cause_of_death }}
          </div>
          <div class="w-1/5">
            {{ corpse.corpse_tag }}
          </div>
          <div
            class="w-1/5 bg-red-300 bg-opacity-30 text-red-500 py-2 px-5 text-xs text-center rounded-full truncate text-ellipse"
            v-if="corpse.gender_of_deceased === 'female'"
          >
            {{ corpse.gender_of_deceased }}
          </div>
          <div
            class="w-1/5 bg-blue-300 bg-opacity-30 text-blue-500 py-2 px-5 text-xs text-center rounded-full truncate text-ellipse"
            v-else
          >
            {{ corpse.gender_of_deceased }}
          </div>
          <div class="w-1/5 relative menu" v-if="actions">
            <div class="flex ml-10">
              <div
                class="p-1 mr-1 rounded-full bg-gray-400"
                v-for="i in 3"
                :key="i"
              />
            </div>
            <div
              class="hidden z-10 absolute top-2 left-10 right-0 bg-white p-3 w-40 shadow-md rounded-lg"
            >
              <div
                class="bg-blue-300 bg-opacity-10 text-blue-400 font-semibold p-2 rounded-lg flex items-center"
                @click="$emit('view', corpse)"
              >
                <edit-icon class="mr-3" />
                View
              </div>
              <div
                class="bg-red-300 bg-opacity-10 text-red-400 font-semibold p-2 rounded-lg flex items-center my-2"
                @click="$emit('delete', corpse)"
              >
                <delete-icon class="mr-3" />
                Delete
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex justify-between items-center bg-yellow-100 py-5 px-5 rounded-md my-4 hover:shadow-md cursor-pointer"
          :key="corpse.corpse_id"
          v-else
          @click="$emit('continue', corpse.corpse_id)"
        >
          <p>
            A corpse with addmission number: <b>{{ corpse.corpse_id }}</b> is
            yet to be detailed by the customer.
          </p>
          <i>Click to continue</i>
        </div>
      </template>
    </div>
    <div class="flex justify-end gap-3">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "corpseTable",
  components: {
    EditIcon: () => import("@/assets/icons/EditIcon.vue"),
    DeleteIcon: () => import("@/assets/icons/DeleteIcon.vue"),
  },
  props: {
    allCorpse: {
      required: true,
      type: Array,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
    actions: {
      required: false,
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      pageNumber: 0,
    };
  },
  computed: {
    corpses() {
      return this.allCorpse;
    },
  },
};
</script>

<style>
.menu:hover .hidden {
  display: block;
}
</style>
